export function AdminWidgetsItemFooter({ loading, error }) {
  if (loading || error) {
    return <div />;
  }
  return (
    <div className="card-footer">
      <div className="legend d-flex just">
        <div className="flex-fill text-center">
          <span
            className="d-inline-block rounded-circle mr-2"
            style={{ background: "#98a4c7" }}
          ></span>
          <small>MTN</small>
        </div>
        <div className="flex-fill text-center">
          <span
            className="d-inline-block rounded-circle mr-2"
            style={{ background: "#36a2eb" }}
          ></span>
          <small>Orange</small>
        </div>
      </div>
    </div>
  );
}
