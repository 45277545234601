import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { http, util } from "../../../_helpers";
import { appConstants } from "../../../_constants";
const { string, formatAmount } = util;

export * from "./show";
export * from "./edit";
export * from "./new";

declare var $: any;
let fetching = false;
let tab: any = null;

export const AdminActivities = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState("none");
  const [items, setItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const getItems = () => {
    if (fetching) return;
    fetching = true;
    http.get("/activities/admin/list").then((res) => {
      if (Array.isArray(res?.data)) {
        setItems(res.data);
        setTimeout(() => {
          if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
            if (tab) {
              tab.clear();
              tab.destroy();
            }
            $(ref.current).empty();
          }
          tab = ($(ref.current) as any).DataTable(
            appConstants.dataTableSettings
          );
        }, 400);
      }
      if (Array.isArray(res?.data?.categoryId)) {
        setCategories(res.data.categoryId);
      }
      setTimeout(() => {
        setDisplay("");
      }, 1000);
      setLoading(false);
      fetching = false;
    });
  };

  const getCategory = (id: any) => {
    const i = categories.findIndex((f) => Number(f.id) === Number(id));
    if (i > -1) {
      return (
        categories[i].code.charAt(0).toUpperCase() + categories[i].code.slice(1)
      );
    }
    return "-";
  };

  const onShow = (state: any) => {
    navigate("show", {
      state: { ...state, category_id: getCategory(state.category_id) },
    });
  };

  const onStatus = async (state: any) => {
    const active = state.active == "1" ? "0" : "1";
    if (active == "0") {
      if (
        !window.confirm(
          `Attention ! vous êtes sur le point de désactiver l'activité ${state.title}.`
        )
      ) {
        return;
      }
    }
    setLoading(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    const res = await http.post(
      encodeURI(`/activities/admin/edit`),
      { ...state, active }
    );
    if (res.code === 200) {
      window.location.reload();
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };

  const onEdit = (state: any) => {
    navigate("edit", { state: { ...state, categories } });
  };

  const onNew = () => {
    navigate("new", { state: { categories } });
  };

  const onDelete = async (state: any) => {
    let v = window.confirm(
      ` Attention vous êtes sur le point de supprimer l'activité dont le titre est ${state.title} et l'uuid ${state.uuid}. Cette action est irreversible !`
    );
    if (v) {
      setLoading(true);
      await http.remove(encodeURI(`/activities/admin/delete/${state.uuid}`));
      getItems();
    }
  };

  useEffect(() => {
    getItems();
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des activités </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3 d-flex justify-content-between">
              {!loading && <h5 style={{ display }}>Liste des activités</h5>}
              {(loading || display === "none") && <h5 className="text-muted">Chargement en cours</h5>}
              {!loading && <button className="btn btn-sm btn-primary" onClick={() => onNew()} style={{ display: "none" }}><i aria-hidden="true" className="fa fa-plus mr-1"></i> Ajouter une activité</button>}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md" style={{ display }}>
                  <table
                    id="admin_table"
                    ref={ref}
                    className="table table-hover"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" style={{ maxWidth: "200px" }}>
                          Titre
                        </th>
                        <th scope="col">Catégorie</th>
                        <th scope="col">Pays</th>
                        <th scope="col">Ville</th>
                        <th scope="col">Prix</th>
                        <th scope="col">Active</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>

                            <td style={{ maxWidth: "200px" }}>
                              {string(item.title)}
                            </td>
                            <td>{string(item._category)}</td>
                            <td>{string(item.country)}</td>
                            <td>{string(item.city)}</td>
                            <td>{string(formatAmount("FCFA", item.price))}</td>
                            <td className="text-center">
                              {item.active == "1" && (
                                <i
                                  className="fa fa-check text-success"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onStatus(item)}
                                ></i>
                              )}
                              {item.active == "0" && (
                                <i
                                  className="fa fa-times text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onStatus(item)}
                                ></i>
                              )}
                            </td>

                            <td>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onShow(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </a>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onEdit(item)}
                              >
                                <i className="far fa-edit"></i>
                              </a>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0"
                                onClick={() => onDelete(item)}
                              >
                                <i className="far fa-trash-alt"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
