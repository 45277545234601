import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { util } from "../../../_helpers";
const { string } = util;

export const AdminOtpCodesShow = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  const { state } = useLocation();
  useEffect(() => {
    setItems(
      Object.entries(state as any).map((a) => ({ label: a[0], value: a[1] }))
    );
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des codes otp </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Détails d'un code otp</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md">
                  <table
                    className="row-border hover w-100 table-fixed"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            style={{ borderBottom: "2px solid #eee" }}
                          >
                            <td
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              <b>{item.label}</b>
                            </td>
                            <td
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              {string(item.value)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
