import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appConstants } from "../../../_constants";
import { http, util } from "../../../_helpers";
const { string } = util;

export * from "./show";

declare var $: any;
let fetching = false;
let tab: any = null;

export const AdminOtpCodes = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<any[]>([]);

  const getItems = () => {
    if (fetching) return;
    fetching = true;

    http.get("/users/authenticationcodes/").then((res) => {
      if (Array.isArray(res?.data)) {
        setItems(res.data);
        setTimeout(() => {
          if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
            if (tab) {
              tab.clear();
              tab.destroy();
            }
            $(ref.current).empty();
          }
          tab = ($(ref.current) as any).DataTable(
            appConstants.dataTableSettings
          );
        }, 400);
      }
      setLoading(false);
      fetching = false;
    });
  };

  const onShow = (state: any) => {
    navigate("show", { state });
  };

  useEffect(() => {
    getItems();

    return () => {
      if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
        if (tab) {
          tab.clear();
          tab.destroy();
        }
        $(ref.current).empty();
      }
    };
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des codes otp </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Liste des codes otp</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md">
                  <table
                    id="admin_table"
                    ref={ref}
                    className="table table-hover"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Code</th>
                        <th scope="col">Codes générés</th>
                        <th scope="col">Éssais</th>
                        <th scope="col" style={{ width: "150px"}}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{string(item.user)}</td>
                            <td>{string(item.code)}</td>
                            <td>{string(item.generated_code)}</td>
                            <td>{string(item.trials_code)}</td>
                            <td>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onShow(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
