import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export function AdminKpiItem({ item: { icon, title, dataCallBack, path } }) {
  const [value, setValue] = useState("Chargement ...");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    if (dataCallBack) {
      dataCallBack().then((res: any) => {
        if (typeof res?.data !== "undefined") {
          setValue(res.data);
        } else {
          setError(true);
        }
      });
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }, []);

  return (
    <div
      className="col-md-6 col-lg-3 mb-4 pointer"
      onClick={() => navigate(path)}
    >
      <div className="card border-0 rounded-0">
        <div className="card-body">
          <div className="card-innerBody d-flex align-items-center">
            <div className="card-icon text-light">
              <i className={icon} aria-hidden="true"></i>
            </div>
            <div className="ml-auto">
              <p className="card-label text-right text-muted">{title}</p>
              <h6 className="card-text text-right ">
                {error && !loading && (
                  <span className="text-danger">Erreur !</span>
                )}
                {!error && loading && (
                  <span className="text-muted">{value}</span>
                )}
                {!loading && !error && (
                  <span className="text-dark">{value}</span>
                )}
              </h6>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex ">
          <Link to={path}>
            <small className="text-muted">En savoir plus</small>
          </Link>
        </div>
      </div>
    </div>
  );
}
