import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http } from "../../../_helpers";

export const AdminActivitiesEdit = () => {
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const [data, setData] = useState<any>(state);

  const navigate = useNavigate();

  const onChange = (key: any, value: any) => {
    console.log(key, value);
    setData({ ...data, [key]: value });
  };

  const onSubmit = async () => {
    setLoading(true);
    const res = await http.post(
      encodeURI(`/activities/admin/edit`),
      { ...data }
    );
    if (res.code === 200) {
      navigate(-1);
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!state) {
      navigate("/admin/activities");
    }
  }, []);
  if (!state) {
    return <div />;
  }
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des activités </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Modifier une activité</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="title">Titre</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="title"
                          value={data.title}
                          onChange={(e) => onChange("title", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="category_id">Catégorie</label>
                        <select
                          value={data.category}
                          onChange={(e) =>
                            onChange("category", e.target.value)
                          }
                          className="form-control rounded-0"
                          id="category_id"
                        >
                          {data.categories.map((c) => (
                            <option key={c.id} value={c.id}>
                              {c.code.charAt(0).toUpperCase() + c.code.slice(1)}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="place_name">Adresse</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="place_name"
                          value={data.address}
                          onChange={(e) =>
                            onChange("place_name", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="city">Ville</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="city"
                          value={data.city}
                          onChange={(e) => onChange("city", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="zip_code">Zip code</label>
                        <input
                          type="number"
                          className="form-control rounded-0"
                          id="zip_code"
                          value={data.zipCode}
                          onChange={(e) => onChange("zipCode", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="country">Pays</label>
                        <select
                          value={data.country}
                          onChange={(e) => onChange("country", e.target.value)}
                          className="form-control rounded-0"
                          id="country"
                        >
                          <option value="CA">Canada</option>
                          <option value="AF">Afghanistan</option>
                          <option value="ZA">Afrique du sud</option>
                          <option value="AX">Åland, îles</option>
                          <option value="AL">Albanie</option>
                          <option value="DZ">Algérie</option>
                          <option value="DE">Allemagne</option>
                          <option value="AD">Andorre</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctique</option>
                          <option value="AG">Antigua et barbuda</option>
                          <option value="SA">Arabie saoudite</option>
                          <option value="AR">Argentine</option>
                          <option value="AM">Arménie</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australie</option>
                          <option value="AT">Autriche</option>
                          <option value="AZ">Azerbaïdjan</option>
                          <option value="BS">Bahamas</option>
                          <option value="BH">Bahreïn</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbade</option>
                          <option value="BY">Bélarus</option>
                          <option value="BE">Belgique</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Bénin</option>
                          <option value="BM">Bermudes</option>
                          <option value="BT">Bhoutan</option>
                          <option value="BO">
                            Bolivie, l'état plurinational de
                          </option>
                          <option value="BQ">
                            Bonaire, saint eustache et saba
                          </option>
                          <option value="BA">Bosnie herzégovine</option>
                          <option value="BW">Botswana</option>
                          <option value="BV">Bouvet, île</option>
                          <option value="BR">Brésil</option>
                          <option value="BN">Brunei darussalam</option>
                          <option value="BG">Bulgarie</option>
                          <option value="BF">Burkina faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KY">Caïmans, îles</option>
                          <option value="KH">Cambodge</option>
                          <option value="CM">Cameroun</option>
                          <option value="CV">Cap vert</option>
                          <option value="CF">Centrafricaine, république</option>
                          <option value="CL">Chili</option>
                          <option value="CN">Chine</option>
                          <option value="CX">Christmas, île</option>
                          <option value="CY">Chypre</option>
                          <option value="CC">Cocos (keeling), îles</option>
                          <option value="CO">Colombie</option>
                          <option value="KM">Comores</option>
                          <option value="CG">Congo</option>
                          <option value="CD">
                            Congo, la république démocratique du
                          </option>
                          <option value="CK">Cook, îles</option>
                          <option value="KR">Corée, république de</option>
                          <option value="KP">
                            Corée, république populaire démocratique de
                          </option>
                          <option value="CR">Costa rica</option>
                          <option value="CI">Côte d'ivoire</option>
                          <option value="HR">Croatie</option>
                          <option value="CU">Cuba</option>
                          <option value="CW">Curaçao</option>
                          <option value="DK">Danemark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DO">Dominicaine, république</option>
                          <option value="DM">Dominique</option>
                          <option value="EG">Égypte</option>
                          <option value="SV">El salvador</option>
                          <option value="AE">Émirats arabes unis</option>
                          <option value="EC">Équateur</option>
                          <option value="ER">Érythrée</option>
                          <option value="ES">Espagne</option>
                          <option value="EE">Estonie</option>
                          <option value="US">États unis</option>
                          <option value="ET">Éthiopie</option>
                          <option value="FK">Falkland, îles (malvinas)</option>
                          <option value="FO">Féroé, îles</option>
                          <option value="FJ">Fidji</option>
                          <option value="FI">Finlande</option>
                          <option value="FR">France</option>
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambie</option>
                          <option value="GE">Géorgie</option>
                          <option value="GS">
                            Géorgie du sud et les îles sandwich du sud
                          </option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Grèce</option>
                          <option value="GD">Grenade</option>
                          <option value="GL">Groenland</option>
                          <option value="GP">Guadeloupe</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <option value="GG">Guernesey</option>
                          <option value="GN">Guinée</option>
                          <option value="GW">Guinée bissau</option>
                          <option value="GQ">Guinée équatoriale</option>
                          <option value="GY">Guyana</option>
                          <option value="GF">Guyane française</option>
                          <option value="HT">Haïti</option>
                          <option value="HM">
                            Heard et îles macdonald, île
                          </option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong kong</option>
                          <option value="HU">Hongrie</option>
                          <option value="IM">Île de man</option>
                          <option value="UM">
                            Îles mineures éloignées des états unis
                          </option>
                          <option value="VG">Îles vierges britanniques</option>
                          <option value="VI">
                            Îles vierges des états unis
                          </option>
                          <option value="IN">Inde</option>
                          <option value="ID">Indonésie</option>
                          <option value="IR">
                            Iran, république islamique d'
                          </option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Irlande</option>
                          <option value="IS">Islande</option>
                          <option value="IL">Israël</option>
                          <option value="IT">Italie</option>
                          <option value="JM">Jamaïque</option>
                          <option value="JP">Japon</option>
                          <option value="JE">Jersey</option>
                          <option value="JO">Jordanie</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KG">Kirghizistan</option>
                          <option value="KI">Kiribati</option>
                          <option value="KW">Koweït</option>
                          <option value="LA">
                            Lao, république démocratique populaire
                          </option>
                          <option value="LS">Lesotho</option>
                          <option value="LV">Lettonie</option>
                          <option value="LB">Liban</option>
                          <option value="LR">Libéria</option>
                          <option value="LY">Libye</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lituanie</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macao</option>
                          <option value="MK">
                            Macédoine, l'ex république yougoslave de
                          </option>
                          <option value="MG">Madagascar</option>
                          <option value="MY">Malaisie</option>
                          <option value="MW">Malawi</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malte</option>
                          <option value="MP">Mariannes du nord, îles</option>
                          <option value="MA">Maroc</option>
                          <option value="MH">Marshall, îles</option>
                          <option value="MQ">Martinique</option>
                          <option value="MU">Maurice</option>
                          <option value="MR">Mauritanie</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexique</option>
                          <option value="FM">
                            Micronésie, états fédérés de
                          </option>
                          <option value="MD">Moldova, république de</option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolie</option>
                          <option value="ME">Monténégro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibie</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Népal</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigéria</option>
                          <option value="NU">Niué</option>
                          <option value="NF">Norfolk, île</option>
                          <option value="NO">Norvège</option>
                          <option value="NC">Nouvelle calédonie</option>
                          <option value="NZ">Nouvelle zélande</option>
                          <option value="IO">
                            Océan indien, territoire britannique de l'
                          </option>
                          <option value="OM">Oman</option>
                          <option value="UG">Ouganda</option>
                          <option value="UZ">Ouzbékistan</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palaos</option>
                          <option value="PS">
                            Palestinien occupé, territoire
                          </option>
                          <option value="PA">Panama</option>
                          <option value="PG">Papouasie nouvelle guinée</option>
                          <option value="PY">Paraguay</option>
                          <option value="NL">Pays bas</option>
                          <option value="PE">Pérou</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Pologne</option>
                          <option value="PF">Polynésie française</option>
                          <option value="PR">Porto rico</option>
                          <option value="PT">Portugal</option>
                          <option value="QA">Qatar</option>
                          <option value="RE">Réunion</option>
                          <option value="RO">Roumanie</option>
                          <option value="GB">Royaume uni</option>
                          <option value="RU">Russie, fédération de</option>
                          <option value="RW">Rwanda</option>
                          <option value="EH">Sahara occidental</option>
                          <option value="BL">Saint barthélemy</option>
                          <option value="SH">
                            Sainte hélène, ascension et tristan da cunha
                          </option>
                          <option value="LC">Sainte lucie</option>
                          <option value="KN">Saint kitts et nevis</option>
                          <option value="SM">Saint marin</option>
                          <option value="MF">
                            Saint martin(partie française)
                          </option>
                          <option value="SX">
                            Saint martin (partie néerlandaise)
                          </option>
                          <option value="PM">Saint pierre et miquelon</option>
                          <option value="VA">
                            Saint siège (état de la cité du vatican)
                          </option>
                          <option value="VC">
                            Saint vincent et les grenadines
                          </option>
                          <option value="SB">Salomon, îles</option>
                          <option value="WS">Samoa</option>
                          <option value="AS">Samoa américaines</option>
                          <option value="ST">Sao tomé et principe</option>
                          <option value="SN">Sénégal</option>
                          <option value="RS">Serbie</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra leone</option>
                          <option value="SG">Singapour</option>
                          <option value="SK">Slovaquie</option>
                          <option value="SI">Slovénie</option>
                          <option value="SO">Somalie</option>
                          <option value="SD">Soudan</option>
                          <option value="SS">Soudan du sud</option>
                          <option value="LK">Sri lanka</option>
                          <option value="SE">Suède</option>
                          <option value="CH">Suisse</option>
                          <option value="SR">Suriname</option>
                          <option value="SJ">Svalbard et île jan mayen</option>
                          <option value="SZ">Swaziland</option>
                          <option value="SY">Syrienne, république arabe</option>
                          <option value="TJ">Tadjikistan</option>
                          <option value="TW">Taïwan, province de chine</option>
                          <option value="TZ">
                            Tanzanie, république unie de
                          </option>
                          <option value="TD">Tchad</option>
                          <option value="CZ">Tchèque, république</option>
                          <option value="TF">
                            Terres australes françaises
                          </option>
                          <option value="TH">Thaïlande</option>
                          <option value="TL">Timor leste</option>
                          <option value="TG">Togo</option>
                          <option value="TK">Tokelau</option>
                          <option value="TO">Tonga</option>
                          <option value="TT">Trinité et tobago</option>
                          <option value="TN">Tunisie</option>
                          <option value="TM">Turkménistan</option>
                          <option value="TC">Turks et caïcos, îles</option>
                          <option value="TR">Turquie</option>
                          <option value="TV">Tuvalu</option>
                          <option value="UA">Ukraine</option>
                          <option value="UY">Uruguay</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VE">
                            Venezuela, république bolivarienne du
                          </option>
                          <option value="VN">Viet nam</option>
                          <option value="WF">Wallis et futuna</option>
                          <option value="YE">Yémen</option>
                          <option value="ZM">Zambie</option>
                          <option value="ZW">Zimbabwe​​​​​</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="longitude">Longitude</label>
                        <input
                          type="number"
                          step={"0.0000001"}
                          className="form-control rounded-0"
                          id="longitude"
                          value={data.longitude}
                          onChange={(e) =>
                            onChange("longitude", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="longitude">Latitude</label>
                        <input
                          type="number"
                          step={"0.0000001"}
                          className="form-control rounded-0"
                          id="latitude"
                          value={data.latitude}
                          onChange={(e) => onChange("latitude", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="short_description">
                          Description courte
                        </label>
                        <textarea
                          value={data.shortDescription}
                          onChange={(e) =>
                            onChange("shortDescription", e.target.value)
                          }
                          id="short_description"
                          className="form-control rounded-0"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="long_description">
                          Description longue
                        </label>
                        <textarea
                          rows={15}
                          value={data.longDescription}
                          onChange={(e) =>
                            onChange("longDescription", e.target.value)
                          }
                          id="long_description"
                          className="form-control rounded-0"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => onSubmit()}
                    className="btn btn-lightning rounded-0 mb-2 mr-2"
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-outline-lightning rounded-0 mb-2"
                  >
                    Retour
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
