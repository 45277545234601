import { immerable } from "immer";

export class BaseModel {
  [immerable] = true;

  private static _instance: BaseModel | undefined;

  user: any = {};
  value = 0;
  pinnedSidebar = false;
  toggledSidebar = false;
  userPath: any;

  constructor() {
    if (BaseModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use BaseModel.getInstance() instead of new."
      );
    }
    BaseModel._instance = this;
  }

  public static getInstance(): BaseModel {
    if (!BaseModel._instance) {
      return new BaseModel();
    }
    return BaseModel._instance;
  }

  public static reset(): BaseModel {
    BaseModel._instance = undefined;
    return BaseModel.getInstance();
  }

  public reset(): BaseModel {
    BaseModel._instance = undefined;
    return BaseModel.getInstance();
  }
}
