import { SidebarNav } from "./SidebarNav";
import { SidebarProfile } from "./SidebarProfile";

export function Sidebar({ sidebarItems, user }) {
  return (
    <aside className="sidebar shadow-sm" style={{ minHeight: "100vh" }}>
      <SidebarProfile user={user} />
      <SidebarNav items={sidebarItems} />
    </aside>
  );
}
