// src/_components/admin/plans/new.tsx
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../../../_helpers";

export const AdminSubscriptionPlansNew = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    price: 0,
    duration : 1,
  });

  const onChange = (key: any, value: any) => {
    setData({ ...data, [key]: value });
  };

  const onSubmit = async () => {
    setLoading(true);
    const res = await http.post("/companies/subscriptions/plans/create", { ...data });
    if (res.code === 200) {
      navigate(-1);
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };

  return (
    <div>
      <h4 className="text-muted mb-4">Créer un nouveau forfait d'abonnement</h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Créer un nouveau forfait</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Nom du forfait</label>
                      <input
                        type="text"
                        className="form-control"
                        value={data.name}
                        onChange={(e) => onChange("name", e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Description du forfait</label>
                      <textarea
                        className="form-control"
                        value={data.description}
                        onChange={(e) => onChange("description", e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Prix du forfait</label>
                      <input
                        type="number"
                        className="form-control"
                        value={data.price}
                        onChange={(e) => onChange("price", e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                    <label>Durée du forfait</label>
                    <input
                      type="number"
                      className="form-control"
                      value={data.duration}
                      onChange={(e) => onChange("duration", e.target.value)}
                    />
                  </div>
                  </div>
                  <br/>

                  
                  <button
                  type="button"
                  onClick={() => onSubmit()}
                  className="btn btn-lightning rounded-0 mb-2 mr-2"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-lightning rounded-0 mb-2"
                >
                  Retour
                </button>
                
  
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};