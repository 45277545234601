import moment from "moment";
import { useEffect, useState } from "react";
import { http, util } from "../../../_helpers";
import { AdminKpi } from "./AdminKpi/AdminKpi";
import { AdminWidgets } from "./AdminWidgets/AdminWidgets";
const { string, formatAmount } = util;
export const adminPrefix = "/admin";

export const adminKpiItems = [
  {
    path: `${adminPrefix}/utilisateurs`,
    title: "Total utilisateurs",
    icon: "fa fa-users",
    key: "totalUsers",
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get("/users/admin/list");
        if (Array.isArray(res?.data)) {
          resole({ data: util.formatInt(res.data.length) });
        }
        resole({});
      });
    },
  },
  {
    path: `${adminPrefix}/compagnies`,
    title: "Total compagnies",
    icon: "fa fa-building",
    key: "totalCompagnies",
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        console.log("CHARGER LES COMPANIES2");
        const res = await http.get("/public/companies/list");
        if (Array.isArray(res?.data)) {
          resole({ data: util.formatInt(res.data.length) });
        }
        resole({});
      });
    },
  },
  {
    path: `${adminPrefix}/utilisateurs`,
    title: "Utilisateurs actifs",
    icon: "fa fa-check",
    key: "totalUsersActif",
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get("/users/admin/list");
        if (Array.isArray(res?.data)) {
          resole({
            data: util.formatInt(
              res.data.filter((u) => u.active == "1").length
            ),
          });
        }
        resole({});
      });
    },
  },
  {
    path: `${adminPrefix}/utilisateurs`,
    title: "Utilisateurs inactifs",
    icon: "fa fa-times",
    key: "totalUsersInactif",
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get("/users/admin/list");
        if (Array.isArray(res?.data)) {
          resole({
            data: util.formatInt(
              res.data.filter((u) => u.active == "0").length
            ),
          });
        }
        resole({});
      });
    },
  },

  {
    path: `${adminPrefix}/activities`,
    title: "Total activités",
    icon: "fa fa-bars",
    key: "totalActivities",
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get("/activities/admin/list");
        console.log(res);
        if (Array.isArray(res?.data)) {
          resole({ data: util.formatInt(res.data.length) });
        }
        resole({});
      });
    },
  },
  {
    path: `${adminPrefix}/payments`,
    title: "Total Paiements Réussis (Activités)",
    icon: "fa fa-dollar-sign",
    key: "totalNonFree",
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get("payments/activities/list?admin=true");
        if (Array.isArray(res?.data)) {
          resole({
            data: util.formatInt(
              res.data.filter(
                (u) => String(u.status) == "SUCCESSFUL" && u.payment_type == "ACTIVITY"
              ).length
            ),
          });
        }
        resole({});
      });
    },
  },
  {
    path: `${adminPrefix}/activities`,
    title: "Activités actifs",
    icon: "fa fa-check",
    key: "totalActivityActif",
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get("/activities/admin/list");
        if (Array.isArray(res?.data)) {
          resole({
            data: util.formatInt(
              res.data.filter((u) => u.active == "1").length
            ),
          });
        }
        resole({});
      });
    },
  },
  {
    path: `${adminPrefix}/activities`,
    title: "Activités inactifs",
    icon: "fa fa-times",
    key: "totalActivitiesInactif",
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get("/activities/admin/list");
        if (Array.isArray(res?.data)) {
          resole({
            data: util.formatInt(
              res.data.filter((u) => u.active == "0").length
            ),
          });
        }
        resole({});
      });
    },
  },
];

export const adminWidgets = [
/*   {
    id: "chart-paiement-count-per-operator",
    title: "Évolution des paiements suivant les opérateurs",
    cls: "col-12 col-xl-6 mb-4",
    type: "chart",
    settings: {
      type: "line",
      cls2: "chart-container",
      canvasAttributes: {
        id: "chart-paiement-count-per-operator",
        width: 2,
        height: 1,
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(0,0,0,.6)",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 8,
                padding: 10,
              },
            },
          ],
        },
        responsive: true,
        legend: {
          position: "bottom",
          display: false,
        },
      },
    },

    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get(`/admin/kpis/paiementsEvolution`);
        if (res.data?.length) {
          let data = {
            labels: res?.data.map((r: any) =>
              moment(r.date).format("MMM YYYY")
            ),
            datasets: [
              {
                label: "MTN",
                data: res?.data.map((r: any) => r.mtn || 0),
                backgroundColor: "transparent",
                borderColor: "#5b6582",
                borderWidth: 2,
              },
              {
                label: "Orange",
                data: res?.data.map((r: any) => r.orange || 0),
                backgroundColor: "transparent",
                borderColor: "#36a2eb",
                borderWidth: 2,
              },
            ],
          };
          resole({ data });
        }
        resole(false);
      });
    },
  }, */
  {
    id: "chart-paiement-per-operator-pro",
    title: "Volume des transactions réalisées par opérateur (Abonnements PRO)",
    cls: "col-12 col-xl-6 mb-4",
    type: "chart",
    settings: {
      type: "bar",
      cls2: "chart-container",
      canvasAttributes: {
        id: "chart-paiement-per-operator-pro",
        width: 2,
        height: 1,
      },
      options: {
        barValueSpacing: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(0,0,0,.6)",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 8,
                padding: 10,
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 0.4,
            },
          ],
        },
        responsive: true,
        legend: {
          position: "bottom",
          display: true,
        },
      },
    },
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get(`/admin/kpis/paiementsEvolutionAmount?context=pro`);
        if (res.data?.length) {
          const dataByMonth = res.data.reduce((acc, current) => {
            const month = moment(current.date).format("MMM YYYY");
            if (!acc[month]) {
              acc[month] = { mtn: 0, orange: 0 };
            }
            acc[month].mtn += current.mtn || 0;
            acc[month].orange += current.orange || 0;
            return acc;
          }, {});
    
          const labels = Object.keys(dataByMonth);
          const mtnData = labels.map((month) => dataByMonth[month].mtn);
          const orangeData = labels.map((month) => dataByMonth[month].orange);
    
          let data = {
            labels,
            datasets: [
              {
                label: "MTN",
                data: mtnData,
                backgroundColor: "#F7F700",
                borderColor: "#F7F700",
                borderWidth: 2,
              },
              {
                label: "Orange",
                data: orangeData,
                backgroundColor: "#FF7801",
                borderColor: "#FF7801",
                borderWidth: 2,
              },
            ],
          };
          resole({ data });
        }
        resole(false);
      });
    },
  },
  {
    id: "chart-paiement-per-operator-activities",
    title: "Volume des transactions réalisées par opérateur (Activités)",
    cls: "col-12 col-xl-6 mb-4",
    type: "chart",
    settings: {
      type: "bar",
      cls2: "chart-container",
      canvasAttributes: {
        id: "chart-paiement-per-operator-activities",
        width: 2,
        height: 1,
      },
      options: {
        barValueSpacing: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(0,0,0,.6)",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 8,
                padding: 10,
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 0.4,
            },
          ],
        },
        responsive: true,
        legend: {
          position: "bottom",
          display: true,
        },
      },
    },
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get(`/admin/kpis/paiementsEvolutionAmount?context=ACTIVITY`);
        if (res.data?.length) {
          const dataByMonth = res.data.reduce((acc, current) => {
            const month = moment(current.date).format("MMM YYYY");
            if (!acc[month]) {
              acc[month] = { mtn: 0, orange: 0 };
            }
            acc[month].mtn += current.mtn || 0;
            acc[month].orange += current.orange || 0;
            return acc;
          }, {});
    
          const labels = Object.keys(dataByMonth);
          const mtnData = labels.map((month) => dataByMonth[month].mtn);
          const orangeData = labels.map((month) => dataByMonth[month].orange);
    
          let data = {
            labels,
            datasets: [
              {
                label: "MTN",
                data: mtnData,
                backgroundColor: "#F7F700",
                borderColor: "#F7F700",
                borderWidth: 2,
              },
              {
                label: "Orange",
                data: orangeData,
                backgroundColor: "#FF7801",
                borderColor: "#FF7801",
                borderWidth: 2,
              },
            ],
          };
          resole({ data });
        }
        resole(false);
      });
    },
  },
 /*  {
    id: "chart-paiement-repartition",
    title: "Repartition des paiements",
    cls: "col-12 col-xl-4 mb-4 align-items-stretch",
    type: "chart",
    hasFooter: true,
    settings: {
      type: "doughnut",
      cls2: "chart-container d-flex h-100 align-items-center justify-content-center",
      canvasAttributes: {
        id: "chart-paiement-repartition",
        style: { height: "100%" },
      },
      options: {
        legend: {
          position: "bottom",
          display: false,
        },
        cutoutPercentage: 80,
      },
    },
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get(`/admin/kpis/paiementsEvolutionCount`);
        if (
          typeof res?.data?.mtn !== "undefined" &&
          typeof res?.data?.orange !== "undefined"
        ) {
          let data = {
            datasets: [
              {
                data: [parseInt(res?.data?.mtn), parseInt(res?.data?.orange)],
                backgroundColor: ["#98a4c7", "#36a2eb"],
              },
            ],
            labels: ["MTN", "Orange"],
          };
          resole({ data });
        }
        resole(false);
      });
    },
  },
  {
    id: "chart-paiements-recent",
    title: "Paiements récents",
    cls: "ccol-12 col-xl-8 mb-4 align-items-stretch",
    type: "table",
    settings: {},
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get(`/admin/kpis/paiementsRecents`);
        if (Array.isArray(res?.data)) {
          resole(res);
        }
        resole(false);
      });
    },
  },
  {
    id: "chart-payouts-recent",
    title: "Payouts récents",
    cls: "col-12 col-xl-6 mb-4",
    type: "table2",
    settings: {},
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get(`/admin/kpis/payoutsRecents`);
        if (Array.isArray(res?.data)) {
          resole(res);
        }
        resole(false);
      });
    },
  },
  {
    id: "chart-requests-recent",
    title: "Requêtes récentes",
    cls: "col-12 col-xl-6 mb-4",
    type: "table3",
    settings: {},
    dataCallBack: async () => {
      return new Promise(async (resole) => {
        const res = await http.get(`/admin/kpis/paiementRequestsRecents`);
        if (Array.isArray(res?.data)) {
          resole(res);
        }
        resole(false);
      });
    },
  },
 */];

export function AdminDashboard() {
  return (
    <div>
      <h4 className="mb-4 text-muted">Dashboard </h4>
      <AdminWidgets items={adminWidgets} />
      <AdminKpi items={adminKpiItems} />

      <Latest />
    </div>
  );
}

const Latest = () => {
  return (
    <div className="row">
      <div className="col-md-6  mb-4 align-items-stretch">
        <LastProfiles />
      </div>
      <div className="col-md-6 mb-4 align-items-stretch">
        <LastReservations />
      </div>
    </div>
  );
};
const LastProfiles = () => {
  const [data, setData] = useState<any>({
    loading: true,
    items: [],
  });

  useEffect(() => {
    http.get("/users/admin/list?last5=true").then((res) => {
      if (Array.isArray(res?.data)) {
        setData({
          loading: false,
          items: res.data,
        });
      } else {
        setData({
          loading: false,
          items: [],
        });
      }
    });
  }, []);

  const { loading, items } = data;

  return (
    <div className="card h-100 border-0 rounded-0">
      <div className="card-title mb-1 p-3 d-flex">
        <h6>
          {loading ? "Chargement en cours ... " : "Dernièrs utilisateurs créés"}
        </h6>
        <a className="btn ml-auto p-0 text-lightning">
          <i className="fas fa-ellipsis-h"></i>
        </a>
      </div>
      <div className="card-body">
        <div className="table-responsive-md">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Prénom</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Téléphone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody className="no-border-x">
                {items.map((item, i) => (
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{string(item.firstName)}</td>
                    <td>{string(item.lastName)}</td>
                    <td>{string(item.phoneNumber)}</td>
                    <td>{string(item.email)}</td>
                    <td>{string(item.status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const LastReservations = () => {
  const [data, setData] = useState<any>({
    loading: true,
    items: [],
  });

  useEffect(() => {
    http.get("/payments/activities/list?last5=true&admin=true").then((res) => {
      console.log("=== ", res);
      if (Array.isArray(res?.data)) {
        setData({
          loading: false,
          items: res.data,
        });
      } else {
        setData({
          loading: false,
          items: [],
        });
      }
    });
  }, []);

  const { loading, items } = data;

  return (
    <div className="card h-100 border-0 rounded-0">
      <div className="card-title mb-1 p-3 d-flex">
        <h6>
          {loading ? "Chargement en cours ... " : "Dernières reservations"}
        </h6>
        <a className="btn ml-auto p-0 text-lightning">
          <i className="fas fa-ellipsis-h"></i>
        </a>
      </div>
      <div className="card-body">
        <div className="table-responsive-md">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>

                  <th scope="col">Activity Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Phone</th>
                </tr>
              </thead>
              <tbody className="no-border-x">
                {items.map((item, i) => (
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>

                    <td>{string(item.activity_name)}</td>
                    <td>
                      {string(item.amount)} {string(item.currency_code)}
                    </td>
                    <td>{string(item.company_name)}</td>
                    <td className="text-center">
                      <td>{string(item.status)}</td>
                    </td>
                    <td>{string(item.phone_number)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
