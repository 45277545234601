import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http, util } from "../../../_helpers";
import { format } from "date-fns";


export const AdminSubscriptionPlansEdit = () => {

  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const [data, setData] = useState<any>(state);
  const navigate = useNavigate();
  console.log(data)
  const onChange = (key: any, value: any) => {
    setData({ ...data, [key]: value });
    console.log(key, value);
  };

  const onSubmit = async () => {
    setLoading(true);
    console.log("AVANT",data.id);
    const res = await http.patch(
      encodeURI(`/companies/subscriptions/plans/${data.id}`),
      { ...data }
    );
    if (res.code === 200) {
      console.log(res.data)
      navigate(-1);
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!state) {
      navigate("/admin/administrateurs");
    }
  }, []);
  if (!state) {
    return <div />;
  }
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des forfaits d'abonnements </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Modifier un forfait</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <form>
                 
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="plan_name">Nom</label>
                        <input
                          type="string"
                          placeholder="Nom du forfait"
                          className="form-control rounded-0"
                          id="plan_name"
                          value={data.name}
                          onChange={(e) =>
                            onChange("name", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                            <label htmlFor="plan_price">Prix</label>
                            <input
                              type="number"
                              placeholder="Prix du forfait d'abonnement"
                              className="form-control rounded-0"
                              id="plan_price"
                              value={data.price}
                              onChange={(e) =>
                                onChange("price", e.target.value)
                              }
                            />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                            <label htmlFor="duration">Durée</label>
                            <input
                              type="number"
                              placeholder="Durée du forfait d'abonnement en jours"
                              className="form-control rounded-0"
                              id="duration"
                              value={data.duration}
                              onChange={(e) =>
                                onChange("duration", e.target.value)
                              }
                            />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input
                              type="string"
                              placeholder="Courte description"
                              className="form-control rounded-0"
                              id="description"
                              value={data.description}
                              onChange={(e) =>
                                onChange("description", e.target.value)
                              }
                            />
                      </div>
                    </div>
                    
                  </div>
                  <button
                    type="button"
                    onClick={() => onSubmit()}
                    className="btn btn-lightning rounded-0 mb-2 mr-2"
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-outline-lightning rounded-0 mb-2"
                  >
                    Retour
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
