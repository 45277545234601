// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJi5jd8DpOxAsEbFNLYQN0_4Zmc0-tedU",
  authDomain: "sportaabe-db345.firebaseapp.com",
  databaseURL: "https://sportaabe-db345-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sportaabe-db345",
  storageBucket: "sportaabe-db345.appspot.com",
  messagingSenderId: "1065434090271",
  appId: "1:1065434090271:web:551e116466efa95aafbe2a",
  measurementId: "G-00SDB1H5TS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export default storage;