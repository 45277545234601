import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export function SidebarNav({ items }) {
  const { pathname } = useLocation();

  const isActive = (pth: string) => {
    const exacts = ["/admin"];
    if (exacts.indexOf(pth) !== -1) {
      if (pth === pathname) {
        return "active";
      }
    } else if (pathname.indexOf(pth) !== -1) {
      return "active";
    }
  };

  return (
    <nav className="sidebar-menu pt-3 pb-3">
      {items.map((item: any, i: any) => {
        return (
          <span key={i}>
            <div className="menu-header mt-3 mb-2">{item.label}</div>
            <ul className="menu-items">
              {item.children.map((child: any, j: any) => {
                return (
                  <li key={j}>
                    <Link
                      to={child.path}
                      className={isActive(child.path)}
                      data-toggle="tooltip"
                      data-placement="right"
                      title={child.label}
                    >
                      <i className={child.icon}></i>
                      <span>{child.label}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </span>
        );
      })}
    </nav>
  );
}
