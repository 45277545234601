import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseModel } from "../_models";

const initialState = BaseModel.getInstance();

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    reset: (state) => {
      state = state.reset();
    },
    replace: (state, action: PayloadAction<BaseModel>) => {
      state = action.payload;
    },
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
  },
});

export const { reset, replace, increment, decrement } = appSlice.actions;

export const appReducer = appSlice.reducer;
