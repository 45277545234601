import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http } from "../../../_helpers";

let long_description_text =
  "Description: \n\nDuration:\n\nLevel: \n\nClothing instructions: \n\n" +
  "Equipment you need to bring: \n\nGender restriction: \n\n" +
  "Activity caters for: \n\nDisabilities and conditions accommodated: \n\nAccepted payment methods: \n\n" +
  "Suitable for ages: \n\nChanging Facilities:\n\nShowers:\n\nWheelchair accessible:\n\nParking: \n\n";

const ContentWraper = ({ children }) => {

  return <div>
    <h4 className="text-muted mb-4">Gestion des activités </h4>
    <div className="row mb-4">
      <div className="col-md-12">
        <div className="card border-0 rounded-0">
          <div className="card-title mb-1 p-3">
            <h5>Créer une activité</h5>
          </div>
          <div className="card-body">
            <form>
              {children}
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
}
const SelectCompany = ({ onChange, data, onNext }) => {

  const onValidate = () => {
    const i = data.companies.findIndex((c) => c.uuid === data.company);
    if (data.company) {
      const v = window.confirm(`Vous êtes sur le point de créer une activité sur le compte de la compagnie "${data.companies[i].company_name.toUpperCase()}". Assurez-vous auprès de leur direction que vous avez le droit de le faire.`);
      if (v) onNext();
    } else {
      window.alert("Veuillez sélectionner une company !!!");
    }
  }
  return <ContentWraper>
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="title">Sélectionner une companie pour commencer</label>
          <div className="d-flex">
            <select value={data.country} onChange={(e) => onChange("company", e.target.value)} className="form-control rounded-0">
              <option value={""}>Selectionner ... </option>
              {data.companies.map((c) => <option key={c.uuid} value={c.uuid}> {c.company_name}</option>)}
            </select>
            <button className="ml-1 btn btn-sm btn-primary justify-content-center align-items-center d-flex" onClick={() => onValidate()}><i aria-hidden="true" className="fa fa-plus mr-1"></i> Valider</button>
          </div>
        </div>
      </div>
    </div>
  </ContentWraper>
}

export const AdminActivitiesNew = () => {
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const [data, setData] = useState<any>(state);
  const [step, setStep] = useState<any>(1);
  const [companies, setCompanies] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);

  const navigate = useNavigate();

  const onChange = (key: any, value: any) => {
    console.log(key, value);
    setData({ ...data, [key]: value });
  };

  const onSubmit = async () => {
    setLoading(true);
    const res = await http.post(
      encodeURI(`/admin/instants/update/${data.id}`),
      { ...data }
    );
    if (res.status === 200) {
      navigate(-1);
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!(window as any).cats?.length) {
      http.post("/actions/execute?action=get_categories").then((res) => {
        if (Array.isArray(res?.data)) {
          (window as any).cats = res.data;
          setCategories(res?.data);
        }
      });
    } else {
      setCategories((window as any).cats);
    }

    if (!(window as any).comps?.length) {
      http.get("/companies/list").then((res) => {
        if (Array.isArray(res?.data)) {
          (window as any).comps = res.data;
          setCompanies(res?.data);
        }
      });
    } else {
      setCompanies((window as any).comps);
    }

  }, []);

  if (step === 1) {
    return <SelectCompany onNext={() => setStep(2)} onChange={onChange} data={{ ...data, companies }} />
  }

  const bloc = data.bloc ?? 1;

  const labelBloc1 = bloc === 1 ? "Masquer ce bloc" : "Afficher ce bloc";
  const clsBloc1 = bloc === 1 ? "btn-danger" : "btn-success";
  const cls1Bloc1 = bloc === 1 ? "fa-times" : "fa-plus";

  const labelBloc2 = bloc === 2 ? "Masquer ce bloc" : "Afficher ce bloc";
  const clsBloc2 = bloc === 2 ? "btn-danger" : "btn-success";
  const cls1Bloc2 = bloc === 2 ? "fa-times" : "fa-plus";

  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des activités </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Créer une activité</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <form>
                  <BlocInfos data={{ ...data, categories, bloc, clsBloc1, cls1Bloc1, labelBloc1, onChange }} />
                  <BlocAdresse data={{ ...data, categories, bloc, clsBloc2, cls1Bloc2, labelBloc2, onChange }} />
                  <button
                    type="button"
                    onClick={() => onSubmit()}
                    className="btn btn-lightning rounded-0 mb-2 mr-2"
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-outline-lightning rounded-0 mb-2"
                  >
                    Retour
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BlocInfos = ({ data }) => {
  const { categories, bloc, clsBloc1, cls1Bloc1, labelBloc1, onChange } = data;
  return <>
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex justify-content-between">
          <div>
            <h4>Informations générales</h4>
            <p>Titre et description</p>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              onChange("bloc", bloc === 1 ? 0 : 1);
            }}
            style={{ maxHeight: "30px" }}
            className={`btn ${clsBloc1} btn-sm`}>
            <i aria-hidden="true" className={`fa ${cls1Bloc1} mr-1`}></i>
            {labelBloc1}
          </button>
        </div>
        <hr />
      </div>
    </div>
    {bloc === 1 && <>
      <div className="row">
        <div className={`${data.price != "free" ? "col-md-6" : "col-md-12"}`}>
          <div className="form-group">
            <label htmlFor="price">Gratuit ou payant ?</label>
            <select id="price" className="form-control rounded-0" onChange={(e) => onChange("price", e.target.value)}>
              <option value={"paid"}>Payant</option>
              <option value={"free"}>Gratuit</option>
            </select>
          </div>
        </div>
        {data.price != "free" && <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="amount">Montant</label>
            <input
              type="number"
              min={0}
              className="form-control rounded-0"
              id="amount"
              value={data.amount}
              onChange={(e) => onChange("amount", e.target.value)}
            />
          </div>
        </div>}
      </div>
      <div className="row">
        <div className={`${data.place === "online" ? "col-md-6" : "col-md-12"}`}>
          <div className="form-group">
            <label htmlFor="place">En ligne ou Sur site ?</label>
            <select id="place" className="form-control rounded-0" onChange={(e) => onChange("place", e.target.value)}>
              <option value={"onsite"}>Sur site</option>
              <option value={"online"}>En ligne</option>
            </select>
          </div>
        </div>
        {data.place === "online" && <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="link">Lien de la conférence en ligne</label>
            <input
              type="url"
              className="form-control rounded-0"
              id="link"
              value={data.link}
              placeholder="Lien https:// (Google meet, Skype, Zoom, ...etc)"
              onChange={(e) => onChange("link", e.target.value)}
            />
          </div>
        </div>}
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="title">Titre</label>
            <input
              type="title"
              className="form-control rounded-0"
              id="title"
              value={data.title}
              onChange={(e) => onChange("title", e.target.value)}
              placeholder="Donner un titre à votre activité"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="category">Catégorie</label>

            <select id="category" className="form-control rounded-0" onChange={(e) => onChange("category", e.target.value)}>
              <option value={""}>Selectionner ... </option>
              {categories.map((c) => <option key={c.code} value={c.code}> {c.libelle}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="short_description">
              Description courte
            </label>
            <textarea
              value={data.short_description}
              onChange={(e) =>
                onChange("short_description", e.target.value)
              }
              id="short_description"
              className="form-control rounded-0"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="photo">
              Photo de l'évènement
            </label>
            <input
              type="file"
              accept="image/*"
              className="form-control rounded-0"
              id="photo"
              onChange={(e) => onChange("photo", e.target.files?.item(0))}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="long_description">
              Description longue
            </label>
            <textarea
              rows={15}
              value={data.long_description ?? long_description_text}
              onChange={(e) =>
                onChange("long_description", e.target.value)
              }
              id="long_description"
              className="form-control rounded-0"
            ></textarea>
          </div>
        </div>
      </div>
    </>}
  </>
}


const BlocAdresse = ({ data }) => {
  const { categories, bloc, clsBloc2, cls1Bloc2, labelBloc2, onChange } = data;
  return <>
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex justify-content-between">
          <div>
            <h4>Localisation</h4>
            <p>Adresse et coordonnées GPS</p>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              onChange("bloc", bloc === 2 ? 0 : 2);
            }}
            style={{ maxHeight: "30px" }}
            className={`btn ${clsBloc2} btn-sm`}>
            <i aria-hidden="true" className={`fa ${cls1Bloc2} mr-1`}></i>
            {labelBloc2}
          </button>
        </div>
        <hr />
      </div>
    </div>
    {bloc === 2 && <>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="address">Adresse</label>
            <input
              type="text"
              className="form-control rounded-0"
              id="address"
              value={data.address}
              onChange={(e) => onChange("address", e.target.value)}
              placeholder="Nom de la rue"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="city">Ville</label>
            <input
              type="text"
              className="form-control rounded-0"
              id="city"
              value={data.city}
              onChange={(e) => onChange("city", e.target.value)}
              placeholder="Nom de la ville"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="code_postal">Code postal</label>
            <input
              type="text"
              className="form-control rounded-0"
              id="code_postal"
              value={data.code_postal}
              onChange={(e) => onChange("code_postal", e.target.value)}
              placeholder="Code postal"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="longitude">Longitude</label>
            <input
              type="text"
              className="form-control rounded-0"
              id="address"
              value={data.longitude}
              onChange={(e) => onChange("address", e.target.value)}
              placeholder="Nom de la rue"
            />
          </div>
        </div>
      </div>

    </>}
  </>
}