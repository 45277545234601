import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http, util } from "../../../_helpers";

export const AdminSettingsEdit = () => {
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const [data, setData] = useState<any>(state);

  const navigate = useNavigate();

  const onChange = (key: any, value: any) => {
    setData({ ...data, [key]: value });
  };

  const onSubmit = async () => {
    setLoading(true);
    const res = await http.put(
      encodeURI(`/shared/settings/${data.uuid}`),
      { ...data }
    );
    if (res.code === 200) {
      navigate(-1);
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!state) {
      navigate("/admin/settings");
    }
  }, []);
  if (!state) {
    return <div />;
  }
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des paramètres </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Modifier un paramètre</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="valeur">Valeur</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="value"
                          placeholder="Valeur du paramètre"
                          value={data.value}
                          onChange={(e) =>
                            onChange("value", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea 
                          id="description" 
                          name="description"  
                          className="form-control rounded-0" 
                          placeholder="Valeur du paramètre"  
                          value={data.description}
                          onChange={(e) =>
                            onChange("description", e.target.value)
                          }></textarea>
                      </div>
                    </div>
                    
                  </div>
                  
                  <button
                    type="button"
                    onClick={() => onSubmit()}
                    className="btn btn-lightning rounded-0 mb-2 mr-2"
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-outline-lightning rounded-0 mb-2"
                  >
                    Retour
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
