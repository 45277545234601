import { useEffect } from "react";
import { Chart } from "chart.js";

export function AdminWidgetsItemCanvas({ item, setData, loading, error }) {
  const {
    dataCallBack,
    settings: { canvasAttributes, cls2, options, type },
  } = item;
  useEffect(() => {
    const ctx = document.getElementById(canvasAttributes.id);
    if (ctx) {
      let c = (ctx as any).getContext("2d");
      if (dataCallBack) {
        dataCallBack().then((res: any) => {
          let error = false;
          let chart: any = null;
          if (res?.data) {
            chart = new Chart(c, {
              type,
              data: res.data,
              options,
            });
          } else {
            error = true;
          }
          setData({ loading: false, error, chart });
        });
      }
    }
  }, [item]);

  return (
    <div
      className={cls2}
      style={{ position: "relative", display: loading || error ? "none" : "" }}
    >
      <canvas {...canvasAttributes}></canvas>
    </div>
  );
}
