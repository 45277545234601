import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appConstants } from "../../../_constants";
import { http, util } from "../../../_helpers";
const { string } = util;

export * from "./show";
export * from "./edit";
declare var $: any;
let fetching = false;
let tab: any = null;
export const AdminUsers = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<any[]>([]);

  const getItems = () => {
    if (fetching) return;
    fetching = true;

    http.get("/users/admin/list").then((res) => {
      if (Array.isArray(res?.data)) {
        setItems(res.data);
        setTimeout(() => {
          if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
            if (tab) {
              tab.clear();
              tab.destroy();
            }
            $(ref.current).empty();
          }
          tab = ($(ref.current) as any).DataTable(
            appConstants.dataTableSettings
          );
        }, 400);
      }
      setLoading(false);
      fetching = false;
    });
  };

  const onShow = (state: any) => {
    navigate("show", { state });
  };

  const onStatus = async (state: any) => {
  // console.log("onStatus", state.active=="0");
  const superadmin = await util.amIAdmin();
  if(state.isAdmin == "1" && !superadmin){ 
    alert("L'administrateur doit être un super administrateur pour pouvoir activer ou désactiver un administrateur!");
    return;
  }
    const active = state.active == "1" ? "0" : "1";
    if (active == "0") {
      if (
        !window.confirm(
          `Attention ! vous êtes sur le point de désactiver l'utilisateur ${state.phoneNumber}. Cet utilisateur ne pourra plus accéder à l'application.`
        )
      ) {
        return;
      }
    }
    setLoading(true);
    const res = await http.post(
      encodeURI(`/users/admin/update`),
      { ...state, active }
    );
    if (res.code === 200) {
      window.location.reload();
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };

  const onEdit = async (state: any) => {
    const superadmin = await util.amIAdmin();
    console.log("THIS", state);
    if(state.isAdmin == "1" ){ 
      alert("L'administrateur doit être un super administrateur pour pouvoir modifier un administrateur!");
      return;
    }
    navigate("edit", { state });
  };

  const onAdmin = async (state: any) => {
    const superadmin = await util.amIAdmin();
    if(!superadmin){ 
      alert("L'administrateur doit être un super administrateur pour pouvoir attribuer le statut d'administrateur à un utilisateur!");
      return;
    }
    console.log("onAdmin", state.isAdmin);
    const admin = state.isAdmin == "1" ? "0" : "1";
    if (admin == "0") {
      if (
        !window.confirm(
          `Attention ! vous êtes sur le point d'empêcher l'administrateur ${state.phoneNumber} de se connecter en tant qu'administrateur.`
        )
      ) {
        return;
      }
    }
    setLoading(true);
    const res = await http.post(
      encodeURI(`/users/admin/update`),
      { ...state, admin }
    );
    if (res.code === 200) {
      window.location.reload();
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };

  const onDelete = async (state: any) => {
    const superadmin = await util.amIAdmin();
    if(!superadmin){ 
      alert("L'administrateur doit être un super administrateur pour pouvoir supprimer un utilisateur!");
      return;
    }
    let v = window.confirm(
      ` Attention vous êtes sur le point de supprimer le profile d'un utilisateur dont le numéro de télephone est ${state.phoneNumber}. Cette action est irreversible !`
    );
    if (v) {
      setLoading(true);
      await http.remove(encodeURI(`/users/admin/delete/${state.uuid}`));
      getItems();
    }
  };

  useEffect(() => {
    getItems();

    return () => {
      if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
        if (tab) {
          tab.clear();
          tab.destroy();
        }
        $(ref.current).empty();
      }
    };
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des utilisateurs </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Liste des utilisateurs</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md">
                  <table
                    id="admin_table"
                    ref={ref}
                    className="table table-hover"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Prénom</th>
                        <th scope="col">Nom</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col">Admin ?</th>
                        <th scope="col">Date de création</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{string(item.firstName)}</td>
                            <td>{string(item.lastName)}</td>
                            <td>{string(item.phoneNumber)}</td>
                            <td>{string(item.email)}</td>
                            <td className="text-center">
                              {item.active == "1" && (
                                <i
                                  className="fa fa-check text-success"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onStatus(item)}
                                ></i>
                              )}
                              {item.active == "0" && (
                                <i
                                  className="fa fa-times text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onStatus(item)}
                                ></i>
                              )}
                            </td>
                            <td className="text-center">
                              {item.isAdmin == "1" && (
                                <i
                                  className="fa fa-check text-success"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onAdmin(item)}
                                ></i>
                              )}
                              {(item.isAdmin == "0" || item.isAdmin == null ) && (
                                <i
                                  className="fa fa-times text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onAdmin(item)}
                                ></i>
                              )}
                            </td>
                            <td>{string(item.creation_date)}</td>
                            <td>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onShow(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </a>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onEdit(item)}
                              >
                                <i className="far fa-edit"></i>
                              </a>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0"
                                onClick={() => onDelete(item)}
                              >
                                <i className="far fa-trash-alt"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
