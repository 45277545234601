import { AdminKpiItem } from "./AdminKpiItem";

export function AdminKpi({ items }) {
  return (
    <div className="row">
      {items.map((item: any, i: number) => {
        return <AdminKpiItem item={item} key={i} />;
      })}
    </div>
  );
}
