import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http, util } from "../../../_helpers";

export const AdminAdministrateursEdit = () => {
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const [data, setData] = useState<any>(state);

  const navigate = useNavigate();

  const onChange = (key: any, value: any) => {
    setData({ ...data, [key]: value });
  };

  const onSubmit = async () => {
    setLoading(true);
    const res = await http.post(
      encodeURI(`/users/admin/update`),
      { ...data }
    );
    if (res.code === 200) {
      navigate(-1);
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!state) {
      navigate("/admin/administrateurs");
    }
  }, []);
  if (!state) {
    return <div />;
  }
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des administrateurs </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Modifier un administrateur</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="first_name">Prénom</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="first_name"
                          placeholder="Entrez votre prénom"
                          value={data.firstName}
                          onChange={(e) =>
                            onChange("firstName", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="last_name">Nom</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="last_name"
                          placeholder="Entrez votre nom"
                          value={data.lastName}
                          onChange={(e) =>
                            onChange("lastName", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="gender">Genre</label>
                        <select
                          value={data.gender}
                          onChange={(e) => onChange("gender", e.target.value)}
                          className="form-control rounded-0"
                          id="gender"
                        >
                          <option value={"male"}>Masculin</option>
                          <option value={"female"}>Féminin</option>
                          <option value={""}>Autre</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="date_of_birth">Date de naissance</label>
                        <input
                          type="string"
                          className="form-control rounded-0"
                          id="date_of_birth"
                          placeholder="JJ-MM-AAAA"
                          value={data.birthDate}
                          onChange={(e) =>
                            onChange("birthDate", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="weight">Poids</label>
                        <input
                          type="number"
                          step={"0.01"}
                          className="form-control rounded-0"
                          id="weight"
                          placeholder="Entrez votre poids"
                          value={data.weight}
                          onChange={(e) => onChange("weight", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="height">Taille</label>
                        <input
                          type="number"
                          step={"0.01"}
                          className="form-control rounded-0"
                          id="height"
                          placeholder="Entrez votre poids"
                          value={data.height}
                          onChange={(e) => onChange("height", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="address">Adresse</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="address"
                          placeholder="Entrez votre prénom"
                          value={data.address}
                          onChange={(e) => onChange("address", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="city">Ville</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="city"
                          placeholder="Entrez votre nom"
                          value={data.city}
                          onChange={(e) => onChange("city", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="country">Pays</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="country"
                          placeholder="Entrez votre prénom"
                          value={data.country}
                          onChange={(e) => onChange("country", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          id="email"
                          placeholder="Entrez votre nom"
                          value={data.email}
                          onChange={(e) => onChange("email", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => onSubmit()}
                    className="btn btn-lightning rounded-0 mb-2 mr-2"
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-outline-lightning rounded-0 mb-2"
                  >
                    Retour
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
