import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appConstants } from "../../../_constants";
import { http, util } from "../../../_helpers";
const { string } = util;
declare var $: any;
let fetching = false;
let tab: any = null;

export const AdminSupportsShow = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  const { state } = useLocation();
  useEffect(() => {
    setItems(
      Object.entries(state as any)
        .map((a) => ({ label: a[0], value: a[1] }))
        .filter((f) => f.label != "description")
    );
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Support clients </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Détails d'une requête</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md">
                  <table
                    className="row-border hover w-100 table-fixed"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            style={{ borderBottom: "2px solid #eee" }}
                          >
                            <td
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              <b>{item.label}</b>
                            </td>
                            <td
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            > {typeof item.value === "object" ? (
                              <pre>{JSON.stringify(item.value.message, null, 2)}</pre>
                            ) : (
                              string(item.value)
                            )}
                              
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          <div className="card border-0 rounded-0 mt-3">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Description</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && <p> {string((state as any).description)}</p>}
            </div>
          </div>
          <Attachments {...(state as any)} />
          <Responses {...(state as any)} />
        </div>
      </div>
    </div>
  );
};

const Attachments = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    http.get(`/chat/support/requests/${props.id}/attachments`).then((res) => {
      if (Array.isArray(res?.data)) {
        setItems(res.data);
      }
      setLoading(false);
    });
  }, []);
  return (
    <div className="card border-0 rounded-0 mt-3">
      <div className="card-title mb-1 p-3">
        {!loading && <h5>Fichiers joints</h5>}
        {loading && <h5 className="text-muted">Chargement en cours</h5>}
      </div>
      <div className="card-body">
        <div className="table-responsive-md">
          <table className="row-border hover w-100 table-fixed">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, i) => {
                return (
                  <tr key={i} style={{ borderBottom: "2px solid #eee" }}>
                    <td
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <b>{i + 1}</b>
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <a href={item.link} target="_blank">
                        Télécharger
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Responses = (props: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const ref = useRef(null);

  useEffect(() => {
    if (fetching) return;
    fetching = true;
    http.get(`/chat/support/requests/${props.id}/responses`).then((res) => {
      if (Array.isArray(res?.data)) {
        setItems(res.data);
        setTimeout(() => {
          if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
            if (tab) {
              tab.clear();
              tab.destroy();
            }
            $(ref.current).empty();
          }
          tab = ($(ref.current) as any).DataTable(
            appConstants.dataTableSettings
          );
        }, 400);
      }
      setLoading(false);
      fetching = false;
    });
  }, []);

  const onShow = (state: any) => {
    navigate("responses/show", {
      state: { ...state, admin: state.closed == "1" ? "Oui" : "Non" },
    });
  };

  return (
    <div className="card border-0 rounded-0 mt-3">
      <div className="card-title d-flex justify-content-between mb-1 p-3">
        {!loading && <h5>Reponses</h5>}
        {loading && <h5 className="text-muted">Chargement en cours</h5>}
        {!loading && (
          <a
            onClick={() =>
              navigate("responses/new", {
                state: { ...props, requests_id: props.id },
              })
            }
            className="btn btn-sm btn-outline-lightning rounded-0 mr-2"
          >
            <i className="fa fa-plus mr-2"></i>
            Ajouter une reponse
          </a>
        )}
      </div>
      <div className="card-body">
        {!loading && (
          <div className="table-responsive-md">
            <table id="admin_table" ref={ref} className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Message</th>
                  <th scope="col">Admin</th>
                  <th scope="col">Utilisateur</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{i + 1}</th>
                      <td>{string(item.created_at)}</td>
                      <td>{string(item.message)}</td>
                      <td>{item.admin == "1" ? "Oui" : "Non"}</td>
                      <td>{string(item.user)}</td>
                      <td>
                        <a
                          className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                          onClick={() => onShow(item)}
                        >
                          <i className="fa fa-eye"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
