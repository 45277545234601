import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appConstants } from "../../../_constants";
import { http, util } from "../../../_helpers";
//import { AdminSubscriptions } from './index';
import { format } from "date-fns";
const { string } = util;

export * from "./show";
export * from "./edit";
export * from "./new";
declare var $: any;
let fetching = false;
let tab: any = null;

export const AdminSubscriptionPlans = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<any[]>([]);

  const getItems = () => {
    if (fetching) return;
    fetching = true;

    http.get("/companies/subscriptions/plans/list?admin=true").then((res) => {
      if (Array.isArray(res?.data)) {
        setItems(res.data);
        setTimeout(() => {
          if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
            if (tab) {
              tab.clear();
              tab.destroy();
            }
            $(ref.current).empty();
          }
          tab = ($(ref.current) as any).DataTable(
            appConstants.dataTableSettings
          );
        }, 400);
      }
      setLoading(false);
      fetching = false;
    });
  };

  const onShow = (state: any) => {
    navigate("show", { state });
  };

  const onStatus = async (state: any) => {
    const superadmin = await util.amIAdmin();
    if(!superadmin){ 
      alert("L'administrateur doit être un super administrateur pour pouvoir modifier le statut  du forfait d'abonnement!");
      return;
    }
    const active = state.active == "1" ? "0" : "1";
    if (active == "0") {
      if (
        !window.confirm(
          `Attention ! vous êtes sur le point de désactiver le forfait d'abonnement ${state.name}. Ce forfait ne sera plus disponible dans l'application.`
        )
      ) {
        return;
      }
    }
    setLoading(true);
    const res = await http.post(
      encodeURI(`/companies/subscriptions/plans/toggle/${state.id}`),
      { state }
    );
    if (res.code === 200) {
      window.location.reload();
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };




  const onEdit = async (state: any) => {
    const superadmin = await util.amIAdmin();
    if(!superadmin){ 
      alert("L'administrateur doit être un super administrateur pour pouvoir modifier le forfait d'abonnement!");
      return;
    }
    navigate("edit", { state });
  };

  const onNew = async () => {
    const superadmin = await util.amIAdmin();
    if(!superadmin){ 
      alert("L'administrateur doit être un super administrateur pour pouvoir créer un forfait d'abonnement!");
      return;
    }
    navigate("new");
  };

  const onDelete = async (state: any) => {
    const superadmin = await util.amIAdmin();
    if(!superadmin){ 
      alert("L'administrateur doit être un super administrateur pour pouvoir supprimer le forfait d'abonnement!");
      return;
    }
    let v = window.confirm(
      ` Attention vous êtes sur le point de supprimer le forfait d'abonnement de  ${state.name.toUpperCase()}. Cette action est irreversible !`
    );
    if (v) {
      setLoading(true);
      console.log("HEYYYY",state);
      await http.remove(encodeURI(`/subscriptions/plans/admin/remove/${state.id}`));
      getItems();
    }
  };

  useEffect(() => {
    getItems();

    return () => {
      if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
        if (tab) {
          tab.clear();
          tab.destroy();
        }
        $(ref.current).empty();
      }
    };
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des forfaits d'abonnement</h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">

              {loading && <h5 className="text-muted">Chargement en cours</h5>}
              {!loading && (
                <div>
                  <h5>Liste des forfaits</h5>
                  <br/>
                  <button className="btn btn-lightning rounded-0 mb-2 mr-2" 
                  onClick={() => onNew()} 
                  style={{ display: "inline" }}>
                  <i aria-hidden="true" className="fa fa-plus mr-1"></i>
                   Ajouter un forfait
                  </button>
                </div>
              )}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md">
                  <table
                    id="admin_table"
                    ref={ref}
                    className="table table-hover"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nom</th>
                        <th scope="col">Prix</th>
                        <th scope="col">Durée</th>
                        <th scope="col">Description</th>
                        <th scope="col">Abonnements</th>
                        <th scope="col">Statut</th>
                        <th scope="col">Date de création</th>
                        <th scope="col">Dernière mise à jour</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{string(item.name)}</td>
                            <td>{string(item.price)}</td>
                            <td>{string(item.duration)}</td>
                            <td>{string(item.description)}</td>
                            <td className="text-center">{item.subscriptionsCount}</td>


                            <td className="text-center">
                              {item.active == "1" && (
                                <i
                                  className="fa fa-check text-success"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onStatus(item)}
                                ></i>
                              )}
                              {item.active == "0" && (
                                <i
                                  className="fa fa-times text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onStatus(item)}
                                ></i>
                              )}
                            </td>
                            <td>{string(item.createdAt)}</td>
                            <td>{string(item.updatedAt)}</td>
                            
                            <td>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onShow(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </a>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onEdit(item)}
                              >
                                <i className="far fa-edit"></i>
                              </a>
                              {item.subscriptionsCount == "0" && item.active == "0" && (
                                <a
                                  className="btn btn-danger btn-sm btn-outline-lightning rounded-0"
                                  onClick={() => onDelete(item)}
                                >
                                  <i className="far fa-trash-alt"></i>
                                </a>
                              )}
                      

                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
