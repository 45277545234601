import { util,http } from "../../_helpers";
import { useState, useEffect } from 'react';

export function SidebarProfile(props: any) {
  const [user, setUser] = useState<{ firstName: string; lastName: string; displayName: string; profile:{avatar: string}} | null>(null);
  const doLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    util.goTo("/auth/login");
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await http.get('/users/authenticate/info');
        setUser(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserProfile();
  }, []);
  console.log("USERRRR", user?.profile.avatar);

  return (
    <div className="sidebar-profile d-flex flex-column justify-content-center pt-5 pb-3">
      <div className="picture-wrapper mb-4">
        <div className="user-picture m-auto">
          <img
            src={(user && user.profile.avatar) ?? "https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=Admin"}
            className="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div className="profile-details text-center pb-4">
        <h5 className="mb-0">{user && user.displayName}</h5>
        <small
          className="text-underline d-inline-block deconnexion-btn"
          onClick={doLogout}
        >
          Déconnexion
        </small>
      </div>
    </div>
  );
}
