import { useEffect, useState } from "react";
import moment from "moment";
import { BaseModel } from "../../../../_models";
import { Link } from "react-router-dom";
import { util } from "../../../../_helpers";
moment.locale("fr");

export function AdminWidgetsItemTable2({ item, setData, loading, error }) {
  const { dataCallBack } = item;
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (dataCallBack) {
      dataCallBack().then((res: any) => {
        let error = false;
        let chart: any = null;
        if (res?.data) {
          setItems(res.data);
        } else {
          error = true;
        }
        setData({ loading: false, error, chart });
      });
    }
  }, [item]);
  if (loading || error) {
    return <div />;
  }
  return (
    <div className="table-responsive-md">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "200px" }}>Date</th>
              <th className="number">Montant</th>
              <th>Opérateur</th>
              <th>State</th>
              <th className="actions" style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody className="no-border-x">
            {items.length > 0 &&
              items.map((i: any, j: any) => {
                const b =
                  i.paymentMethod.code === "mtn"
                    ? "badge-yellow"
                    : i.paymentMethod.code === "orange"
                    ? "badge-orange"
                    : "";
                const s =
                  i.status === "SUCCESSFUL" || i.status === "SUCCESS"
                    ? "text-success"
                    : i.status === "FAILED"
                    ? "text-danger"
                    : "text-dark";
                return (
                  <tr key={j}>
                    <td>
                      {moment(i.createdAt).format("DD/MM/YYYY [à] HH:mm:ss")}
                    </td>
                    <td className="number">
                      {util.formatAmount("FCFA", i.amount)}
                    </td>
                    <td>
                      <span className={`badge ${b} rounded-0 p-1`}>
                        {i.paymentMethod.libelle}
                      </span>
                    </td>
                    <td className={`${s}`}>{i.status}</td>
                    <td className="actions">
                      <Link
                        to={`${BaseModel.getInstance().userPath}/payouts/show/${
                          i.id
                        }`}
                        className="btn btn-sm btn-outline-lightning pointer rounded-0 mr-2"
                      >
                        <i className="fa fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            {items.length < 1 && (
              <tr>
                <td className="text-center" colSpan={5}>
                  Aucune donnée
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
