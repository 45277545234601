import { useState } from "react";
import { AdminWidgetsItemCanvas } from "./AdminWidgetsItemCanvas";
import { AdminWidgetsItemFooter } from "./AdminWidgetsItemFooter";
import { AdminWidgetsItemTable } from "./AdminWidgetsItemTable";
import { AdminWidgetsItemTable2 } from "./AdminWidgetsItemTable2";
import { AdminWidgetsItemTable3 } from "./AdminWidgetsItemTable3";

export function AdminWidgetsItem({ item }) {
  const { title, cls, type, hasFooter } = item;
  const [data, setData] = useState({
    loading: true,
    error: false,
    chart: null,
  });

  const { loading, error } = data;
  return (
    <div className={cls}>
      <div
        className="card h-100 border-0 rounded-0"
        style={{ minHeight: "260px" }}
      >
        <div className="card-title mb-1 p-3 d-flex">
          <h6>
            {!loading && !error && title}
            {loading && (
              <span className="text-muted">Chargement en cours ...</span>
            )}
            {!loading && error && (
              <span className="text-danger">Erreur lors du chargement !</span>
            )}
          </h6>
          <a className="btn ml-auto p-0 text-lightning">
            <i className="fas fa-ellipsis-h"></i>
          </a>
        </div>
        <div className="card-body">
          {type === "chart" && (
            <AdminWidgetsItemCanvas item={item} {...data} setData={setData} />
          )}
          {type === "table" && (
            <AdminWidgetsItemTable item={item} {...data} setData={setData} />
          )}
          {type === "table2" && (
            <AdminWidgetsItemTable2 item={item} {...data} setData={setData} />
          )}
          {type === "table3" && (
            <AdminWidgetsItemTable3 item={item} {...data} setData={setData} />
          )}
        </div>
        {hasFooter && <AdminWidgetsItemFooter {...data} />}
      </div>
    </div>
  );
}
