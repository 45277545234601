import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http, util } from "../../../_helpers";
const { string } = util;

export const AdminSupportsResShow = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  const { state } = useLocation();
  useEffect(() => {
    setItems(
      Object.entries(state as any)
        .map((a) => ({ label: a[0], value: a[1] }))
        .filter((f) => f.label != "message")
    );
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Support clients </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title d-flex justify-content-between mb-1 p-3">
              {!loading && <h5>Détails d'une reponse</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
              {!loading && (
                <div>
                  <a
                    onClick={() => navigate(-1)}
                    className="btn btn-sm btn-outline-lightning rounded-0 mr-2"
                  >
                    <i className="fa fa-reply mr-2"></i>
                    Retour
                  </a>
                  <a
                    onClick={() =>
                      navigate("/admin/supports/show/responses/new", {
                        state: { ...(state as any), message: "" },
                      })
                    }
                    className="btn btn-sm btn-outline-lightning rounded-0 mr-2"
                  >
                    <i className="fa fa-plus mr-2"></i>
                    Ajouter une reponse
                  </a>
                </div>
              )}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md">
                  <table
                    className="row-border hover w-100 table-fixed"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            style={{ borderBottom: "2px solid #eee" }}
                          >
                            <td
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              <b>{item.label}</b>
                            </td>
                            <td
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              {string(item.value)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          <div className="card border-0 rounded-0 mt-3">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Message</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && <p> {string((state as any).message)}</p>}
            </div>
          </div>
          <Attachments {...(state as any)} />
        </div>
      </div>
    </div>
  );
};

const Attachments = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    http.get(`/chat/support/responses/${props.id}/attachments`).then((res) => {
      if (Array.isArray(res?.data)) {
        setItems(res.data);
      }
      setLoading(false);
    });
  }, []);
  return (
    <div className="card border-0 rounded-0 mt-3">
      <div className="card-title mb-1 p-3">
        {!loading && <h5>Fichiers joints</h5>}
        {loading && <h5 className="text-muted">Chargement en cours</h5>}
      </div>
      <div className="card-body">
        <div className="table-responsive-md">
          <table className="row-border hover w-100 table-fixed">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, i) => {
                return (
                  <tr key={i} style={{ borderBottom: "2px solid #eee" }}>
                    <td
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <b>{i + 1}</b>
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <a href={item.link} target="_blank">
                        Télécharger
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
